<template>
    <v-app>

        <v-app-bar app class="header">
            <div class="d-flex align-center ">
                <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="https://www.vierp.in/logo/edu+camp-01.png"
                    transition="scale-transition" width="150" />

            </div>

            <v-spacer></v-spacer>
            <router-link to="/search-catalog-home" class="sidebar-link">
                <v-list-item link style="float:right;">
                    <v-list-item-icon>
                        <v-icon class="btn-title"
                            style="color:white;font-weight:600;font-size:40px;float:right;">mdi-search-web</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title style="color:white;font-weight:600;font-size:20px;">OPAC
                            Search</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>


        </v-app-bar>

        <v-container class="fill-height main-bg" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="5">
                    <v-card class="elevation-12" style="text-align: center;padding: 1rem;opacity: 0.9;">
                        <img class="logo-img" src="https://www.vierp.in/logo/edu+camp-01.png">
                        <h5 class="title">Library Management System</h5>

                        <h6 class="login-text">Login</h6>
                        <v-card-text>
                            <v-form v-model="valid">
                                <v-text-field @click="clearmsg()" v-model.trim="user.uid" @keyup.enter="signIn"
                                    :rules="[rules.required]" prepend-icon="mdi mdi-account"
                                    placeholder="Enter your login id" clearable required></v-text-field>
                                <v-text-field @click="clearmsg()" v-model="user.pass" @keyup.enter="signIn"
                                    :rules="[v => !!v || 'password required']" prepend-icon="mdi mdi-lock"
                                    placeholder="Enter your password" clearable
                                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)"
                                    :type="value ? 'password' : 'text'" required></v-text-field>
                                <v-row justify="center">
                                    <v-btn class="m-4 " color="primary" :loading="loading" :disabled="!valid" @click="signIn">Login</v-btn>

                                </v-row>
                            </v-form>
                            <br />

                            <v-row justify="center">
                                <v-row justify="center">
                                    <div class="redirection">
                                        <p @click="redirection">Forgot Password</p>
                                    </div>
                                </v-row>
                            </v-row>
                            <v-col cols="12">
                                <div v-if="message != null">
                                    <v-alert :value="alert" outlined type="error" prominent border="left" dismissible>{{
                                        message }}
                                    </v-alert>
                                </div>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <div class="text-center">
            <v-dialog v-model="dialog" width="500">
                <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>
                        Privacy Policy
                    </v-card-title>

                    <v-card-text>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="readPolicy()" :loading="read_policy_load">
                            I accept
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <!-- </v-content> -->
        <!-- <login-footer></login-footer> -->
    </v-app>
</template>

<script>
import axios from "axios";
// import LoginHeader from "./LoginHeader.vue";
// import LoginFooter from "./LoginFooter.vue";
export default {
    // components: {
    //     LoginHeader: LoginHeader,
    //     LoginFooter: LoginFooter
    // },
    data() {
        return {
            user: {
                uid: "",
                pass: "",
                logindevice: ""
            },
            value: String,
            valid: false,
            alert: false,
            message: "",
            dialog: false,
            loading: false,
            read_policy_load: false,
            rules: {
                required: value => !!value || "Required.",
                // uid: value => {
                //     const pattern = /^(  )|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|(  )$/;
                //     return pattern.test(value) || "Invalid e-mail.";
                // }
            },
        };
    },
    methods: {
        checkvalidbrowser() {
            //alert("in check browser...")  
            // var nVer = navigator.appVersion;
            // var nAgt = navigator.userAgent;
            // var browserName = navigator.appName;
            // var fullVersion = '' + parseFloat(navigator.appVersion);
            // var majorVersion = parseInt(navigator.appVersion, 10);
            // var nameOffset, verOffset, ix;
            // var Name = "Unknown OS";
            // var os = "";
            // if ((verOffset = nAgt.indexOf("Edg")) != -1) {
            //     browserName = "Edge";
            //     fullVersion = nAgt.substring(verOffset + 4);
            // } else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
            //     browserName = "Chrome";
            //     fullVersion = nAgt.substring(verOffset + 7);
            // } else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
            //     browserName = "Firefox";
            //     fullVersion = nAgt.substring(verOffset + 8);
            // } else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
            //     browserName = "Safari";
            //     fullVersion = nAgt.substring(verOffset + 7);
            //     if ((verOffset = nAgt.indexOf("Version")) != -1)
            //         fullVersion = nAgt.substring(verOffset + 8);
            // } else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
            //     browserName = "Microsoft Internet Explorer";
            //     fullVersion = nAgt.substring(verOffset + 5);
            // }

            // // In most other browsers, "name/version" is at the end of userAgent 
            // else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            //     (verOffset = nAgt.lastIndexOf('/'))) {
            //     browserName = nAgt.substring(nameOffset, verOffset);
            //     fullVersion = nAgt.substring(verOffset + 1);
            //     if (browserName.toLowerCase() == browserName.toUpperCase()) {
            //         browserName = navigator.appName;
            //     }
            // }
            // // trim the fullVersion string at semicolon/space if present
            // if ((ix = fullVersion.indexOf(";")) != -1)
            //     fullVersion = fullVersion.substring(0, ix);
            // if ((ix = fullVersion.indexOf(" ")) != -1)
            //     fullVersion = fullVersion.substring(0, ix);
            // if (nAgt.toLowerCase().indexOf("windows") >= 0) {
            //     Name = "Windows";
            // } else if (nAgt.toLowerCase().indexOf("mac") >= 0) {
            //     Name = "Mac";
            // } else if (nAgt.toLowerCase().indexOf("x11") >= 0) {
            //     Name = "Unix";
            // } else if (nAgt.toLowerCase().indexOf("android") >= 0) {
            //     Name = "Android";
            // } else if (nAgt.toLowerCase().indexOf("iphone") >= 0) {
            //     Name = "IPhone";
            // } else {
            //     Name = "UnKnown, More-Info: " + nAgt;
            // }
            // //alert("os::"+Name)

            // majorVersion = parseInt('' + fullVersion, 10);
            // if (isNaN(majorVersion)) {
            //     fullVersion = '' + parseFloat(navigator.appVersion);
            //     majorVersion = parseInt(navigator.appVersion, 10);
            // }
            // localStorage.setItem("logindevice", browserName + "_" + Name + "_" + fullVersion);
        },
        clearmsg() {
            this.message = null;
        },
        readPolicy() {
            this.read_policy_load = true
            axios
                .post("/login/readPolicy", this.user)
                .then(res => {
                    window.console.log("res" + res.data.msg);
                    if (res.data.msg == "200") {
                        // this.$router.push("/home");
                    }
                })
                .catch(error => {
                    window.console.log(error);
                    this.message = "Something went wrong!!!";
                })
                .finally(() => {
                    this.dialog = false
                    this.read_policy_load = false
                });
        },
        signIn() {
            console.log('user----', this.user);
            this.checkvalidbrowser();
            if (this.perormLoginFormValidation()) {
                this.user.dns = window.location.hostname;
                this.user.logindevice = localStorage.getItem("logindevice");
                this.message = null
                this.loading=true;
                axios
                    .post("/login/process", this.user)
                    .then(res => {
                        window.console.log("res" + res.data.policy_read);
                        if (res.data.msg == "200") {
                            this.loading=false;
                            localStorage.setItem("awspath", res.data.awspath)
                            localStorage.setItem("EPS-token", res.data.token);
                            localStorage.setItem("EPS-uid", res.data.uid);
                            localStorage.setItem("logo_url", res.data.logo_url);
                            localStorage.setItem("org_name", res.data.org_name);
                            localStorage.setItem("org_trust_name", res.data.org_trust_name);
                            localStorage.setItem("org_address", res.data.org_address);
                            if (res.data.policy_read == true) {
                                if (res.data.firstlogin == "1") {
                                    localStorage.setItem("first-login", res.data.firstlogin);
                                    this.$router.push("/update-password");
                                } else {
                                    localStorage.setItem("first-login", "0");
                                    this.$router.push("/home");
                                }
                            } else {
                                this.dialog = true
                            }
                            //window.location="/home"
                            this.$router.push({ name: "home" }).catch(() => { });
                        } else {
                            this.loading=false;
                            this.message = "Please check your credentials!!!";
                            this.alert = true
                            localStorage.setItem("EPS-token", "");
                            localStorage.setItem("EPS-uid", "");
                        }
                    })
                    .catch(error => {
                        this.loading=false;
                        window.console.log(error);
                        this.message = "Something went wrong!!!";
                    });
            } else {
                this.loading=false;
                console.log("I am in else block");
            }
        },
        clear() {
            this.user.uid = "";
            this.user.pass = "";
        },
        perormLoginFormValidation() {
            if (this.user.uid == "" && this.user.pass == "") {
                return false;
            } else {
                return true;
            }
        },
        redirection() {
            console.log("inside redirection function")
            this.$router.push({ name: 'forgot-password', target: "blank" })
            localStorage.setItem("redirection", "1");
        }
    },
    mounted() {

        this.message = localStorage.getItem("login-page-msg");
        console.log("login-page-msg>>" + localStorage.getItem("login-page-msg"));

        //this.$store.commit('setquick_links', false);
        localStorage.clear();
    }
};
</script>

<style scoped>
.pull-right {
    position: relative;
    text-align: center;
}

.redirection {
    color: grey;
    font-size: 15px;
    text-decoration: underline;
}

.redirection:hover {
    cursor: -webkit-grab;
    cursor: pointer;
    color: blue;
}









.pb0 {
    padding-bottom: 0px !important;
}

.main-bg {
    background-image: url("../assets/library.jpg");
    /* background-color: #8000401f; */
    background-color: lightgrey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* position: relative; */
    /* position:fixed; */
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.login-box {
    background: white;
    width: 37%;
    border-radius: 12px;
    /* height: 70%;
    height: auto; */
    height: fit-content;
    margin-top: 5rem;
    /* margin-bottom: 3rem; */
    text-align: center;
}

.title {
    font-size: 1.6rem !important;
    padding-top: 1rem;
    font-family: ui-monospace;
    color: #800040;
}

.login-text {
    font-size: 1.5rem;
    /* padding-top: 1rem; */
    font-family: ui-monospace;
    font-weight: bold;
    /* padding-bottom: 1rem; */
}

.mr {
    margin-right: 1rem;
}

label {
    margin-top: .5rem;
}

.login-btn {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

a {
    text-decoration: underline;
}

.logo-img {
    width: 10rem;
}

.center {
    justify-content: center;
}

.header {
    /* background: cadetblue !important; */
    background: #000000c7 !important;
}
</style>
